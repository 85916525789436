:root {
    --PhoneInput-color--focus: rgb(100, 180, 45);
    --PhoneInputCountrySelectArrow-opacity: 1;
}

.PhoneInput {
    padding: 0;
    transition: all 0.3s ease 0s;
    outline: none;
}

.PhoneInput:hover {
    border-color: rgb(100, 180, 45);
}

.PhoneInputInput:focus {
    box-shadow: 0 0 0 3px rgba(100, 180, 45, 0.18);
    border-radius: 2px;
}

.PhoneInputCountry {
    border-right: 1px solid #B8B8B8;
    height: 48px;
    width: 35px;
    margin: 0 0 0 11px !important;
}

.PhoneInputInput {
    padding: 6px 11px;
    height: 100%;
    color: #444444;
    border: none;
    background: transparent;
    transition: all 0.3s ease 0s;
}

.PhoneInputInput:focus {
    outline: none;
}

.PhoneInputInput::placeholder {
    color: #B8B8B8;
    opacity: 1;
}

.PhoneInputCountrySelectArrow {
    color: #B8B8B8;
    opacity: 1 !important;
}

.error.PhoneInput {
    border-color: rgb(245, 34, 45);
}

.error .PhoneInputInput:focus {
    box-shadow: 0 0 0 3px rgba(245, 34, 45, 0.18);
}
