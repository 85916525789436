.news-form .ant-form-explain{
    margin-top:5px;
}
.news-form .ant-calendar-picker-input {
    height:50px;
}
.news-form .news-datepicker{
    width:100%;
}
.submit-row{
    margin-top:20px;
}
.newsform-editor .md-RichEditor-root{
    min-height:195px;
}

.ant-upload {
    width: 100%;
}
