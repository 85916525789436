@font-face {
    font-family: "Roboto-Regular";
    src: url(fonts/Roboto-Regular-webfont.eot);
    src: url(fonts/Roboto-Regular-webfont.eot?#iefix)
            format("embedded-opentype"),
        url(fonts/Roboto-Regular-webfont.woff) format("woff"),
        url(fonts/Roboto-Regular-webfont.ttf) format("truetype"),
        url(fonts/Roboto-Regular-webfont.svg#robotoregular) format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-SemiBold";
    src: url(fonts/Roboto-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Bold";
    src: url(fonts/Roboto-Bold-webfont.eot);
    src: url(fonts/Roboto-Bold-webfont.eot?#iefix) format("embedded-opentype"),
        url(fonts/Roboto-Bold-webfont.woff) format("woff"),
        url(fonts/Roboto-Bold-webfont.ttf) format("truetype"),
        url(fonts/Roboto-Bold-webfont.svg#robotobold) format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url(fonts/montserrat-regular-webfont.eot);
    src: url(fonts/montserrat-regular-webfont.eot?#iefix)
            format("embedded-opentype"),
        url(fonts/montserrat-regular-webfont.woff2) format("woff2"),
        url(fonts/montserrat-regular-webfont.woff) format("woff"),
        url(fonts/montserrat-regular-webfont.ttf) format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: url(fonts/montserrat-bold-webfont.eot);
    src: url(fonts/montserrat-bold-webfont.eot?#iefix)
            format("embedded-opentype"),
        url(fonts/montserrat-bold-webfont.woff2) format("woff2"),
        url(fonts/montserrat-bold-webfont.woff) format("woff"),
        url(fonts/montserrat-bold-webfont.ttf) format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 100%;
    height: 100vh;
    font-family: Roboto-Regular;
}

.uppercase input{
    text-transform: uppercase;
 }

.ant-modal-wrap .ant-confirm .ant-modal-content .ant-modal-body {
    padding: 15px 0;
}

.ant-modal-wrap .ant-confirm div.ant-modal-content .ant-confirm-body-wrapper div.ant-confirm-body > i {
    margin-left: 10px;
}

.ant-confirm .ant-modal-content .ant-confirm-body-wrapper .ant-confirm-body .ant-confirm-content {
    margin: 10px 0;
    padding: 20px;
    background-color: #F6F6F6;
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
}

.ant-modal-wrap .ant-confirm .ant-modal-body .ant-confirm-btns {
    margin: 5px 15px 0 0;
}

.ant-confirm .ant-modal-content .ant-confirm-body-wrapper  button.ant-btn {
    padding: 0 55px;
}

/* Mesh-Chat-Service CSS Starts**/
.chatMessageForm .mesh-emoji-button {
    position: absolute;
    border: none;
    right: 20px;
    bottom: 11px;
}

.mess-emoji-icon {
    font-size: 25px;
}

.chatMessageForm{
    padding-left: 5px;
}

.chatMessageForm .md-RichEditor-editor .public-DraftEditorPlaceholder-root {
    margin: 0 -5px -52px !important;
}

.chatMessageForm .md-RichEditor-root {
    padding: 0px !important;
    border: 3px solid rgba(134,134,134,.7) !important;
    border-radius: .375rem !important;
    min-height: 52px;
    width: 99%;
    max-height: 70px;
}

.chatMessageForm .md-RichEditor-editor .public-DraftEditor-content{
    min-height: 65px !important;
}

.chatMessageForm .DraftEditor-editorContainer{
    margin-left: 10px;
    margin-right: 60px;
    overflow: scroll !important;
    max-height: 60px;
    overflow-x: hidden !important;
}

#messageContainer .ant-list-vertical .ant-list-item-meta-avatar{
    display: block !important
}

.chatMessageForm .public-DraftEditor-content:last-child span {
    display: inline-block;
    overflow-wrap: break-word;
    word-break: break-word;
}

#messageListContainer .ant-list-item-content p {
    word-break: break-word;
}

#messageListContainer .ant-list-item-content blockquote{
    word-break: break-word;
}

#messageListContainer .ant-list-item-content strong{
    word-break: break-word;
}

#messageListContainer .ant-list-item-content h1{
    word-break: break-word;
}
/* Mesh-Chat-Service CSS Ends**/
